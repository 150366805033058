.tab-button {
  color: white;
  text-transform: uppercase;
  font-family: rhode, sans-serif;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 0 20px 0;
  padding: 0 10px;
  flex: 1;

  &.selected {
    background: #481D64;
    opacity: 1;
  }

  &.selected:last-child {
    border-radius: 20px 0 0 0;
  }

  .small-alert {
    margin-left: 5px;
    background: red;
    color: white;
    padding: 0 4px;
    border-radius: 5px;
  }
}
