.sign-in {
  .form-group {
    display: flex;
    flex-direction: column;
  }
}

.sign-in-form {
  .password {
    margin-bottom: 20px;
  }

  margin-bottom: 20px;
}
