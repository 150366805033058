@import '../../../../../css/z-indices';

$drawer-height: 450px;
$header-height: 40px;
$content-height: $drawer-height - $header-height;

.ant-drawer {
  z-index: $z-space-race-drawer !important;
}
// This keeps the drawer slightly visible when closed.
.ant-drawer:not(.ant-drawer-open) .ant-drawer-content-wrapper {
  transform: translateY($content-height) !important;
};

.ant-drawer-header {
  display: none;
}

.ant-drawer-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $drawer-height !important;
}

.ant-drawer-content {
  background: rgba(0, 0, 0, 0);
  width: 90% !important;
  height: $content-height;
}

.ant-drawer-wrapper-body {

}

.ant-drawer-body {
  padding: 0;
}

.ant-collapse-content-box {
  //padding: 10px 0 !important;
}

.tab-header {
  display: flex;
  align-items: stretch;
  height: $header-height;
  border-radius: 20px 0 0 0;
  background: rgba(139, 81, 177, 0.8);
}

.flex-spacer {
  flex: 1;
}

.tab-content {
  background: rgba(19, 5, 28, 0.95);
  border: #5D1A89;
  height: $content-height;
  overflow-y: auto;
  box-shadow: #8F2DD0 0 0 6px 0.2px;
  display: flex;
  flex-direction: column;
}

.ant-collapse {
  background-color: unset;
  border: none;
}

.ant-collapse-item {
  border-bottom: 1px solid rgba(72, 29, 100, 1) !important;
}

.ant-collapse-header {
  padding: 0 !important;
}

.ant-collapse-content {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 10px;
}

.ant-collapse-content-active {
  border-top: 1px solid #111111;
}

.ant-collapse-content-box {
  display: flex;
  flex-direction: column;
}

.building-upgrade {
  display: flex;
}

.large-modal {
  .large-modal-close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: white;
  }

  .large-modal-img {
    object-fit: cover;
    max-height: 88vh;
  }

  .large-modal-info {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;

    &.modal-bottom {
      bottom: 0;
    }
  }

  .modal-title {
    text-transform: uppercase;
    font-size: 20px;
  }

  .modal-description {
    margin-bottom: 20px;
  }

  .modal-attribution {
    text-align: right;
    font-style: italic;
    font-size: 10px;
    margin: 0;
  }
}
