@import '../../../../css/global';
@import '../../../../css/z-indices';

.planet-main {

}

.planet-label, .year-label, .instructions {
  color: white;
  text-align: center;
  margin: 0;
}

.planet-label {
  font-size: 12px;
  text-transform: uppercase;
  font-family: rhode, sans-serif;
  margin-bottom: 4px;
}

.year-label {
  font-family: rhode, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.instructions {
  margin: 0 40px;
}

.particle-layer {
  position: absolute;
  z-index: $z-space-race-particles;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.planet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

#planet-image {
  @extend .nodrag;
  max-width: 70%;
  border-radius: 100%;
  transform: translate(0px, 0px)!important;
}

#planet-area {
  position: absolute;
  width: 70%;
  height: auto;
  opacity: 1;
}

#hand-container {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -100px;
}

#hand-image {
  z-index: 1;
  transform-origin: center center;
}

.number-particle {
  border: 5px solid red;
}
