.firebase-image-loading, .firebase-image-error {
  display: flex;
  justify-content: center;
  align-items: center;
}

.firebase-image-loading {
  border: 1px solid lightgray;
  background: #eeeeee;
}

.firebase-image-error {
  border: 1px solid #ff3333;
  background: #ffcccc;
}

.firebase-image {
  border: 1px solid lightgray;
}
