.tech-tab {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.tech-list {
  list-style: none;
  padding: 20px;
}

.tech-intro {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.2);
  margin: 0;
  color: white;
  text-align: center;
  border-bottom: 1px solid rgba(72, 29, 100, 1);
}

$item-height: 75px;
.tech-item {
  background: black center;
  display: flex;
  height: $item-height;
  border: 1px solid gray;
  border-radius: 20px 0 20px 0;
  margin-bottom: 10px;
  overflow: hidden;


  .tech-image, .tech-bg-image {
    width: 100%;
    height: 100%;
    //object-fit: cover;
    //position: absolute;
  }

  .tech-bg-image {
    display: flex;
    justify-content: flex-end;
    background-position-y: center;
    background-position-x: right;
  }

  .tech-image {
    filter: grayscale(100%);
    background-position-x: right;
    background-position-y: center;
    position: relative;
    //right: -7px;
    border-left: 5px solid white;
  }

  .tech-item-info {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin: 10px 0 0 10px;
  }

  .tech-item-name, .tech-item-info {
    color: white;
    //color: rgba(139, 81, 177, 1);
  }

  .tech-item-name {
    font-family: rhode, sans-serif;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    //text-shadow: 0 2px 10px #481D64;
    text-shadow: 0 2px 2px #000000, 0 2px 10px #000000;
  }

  .tech-item-flavor {
    font-size: 12px;
    padding-right: 20px;
    //text-shadow: 0 1px 5px #481D64;
    text-shadow: 0 0 2px #000000, 0 2px 2px #000000;
  }
}

.tech-item-unknown {
  box-shadow: #8F2DD0 0 0 6px 0.2px;

  .tech-item-flavor {
    font-style: italic;
  }
}
