$border-radius: 20px;
$bg-color: rgb(137, 201, 188);
$scale: 1;
$sh-h: 0 * $scale;
$sh-v: 0 * $scale;
$sh-bl: 40px * $scale;
$sh-spr: 0 * $scale;
$sh-col: rgba(255, 255, 255, 0.7);
$header-color: rgba(253, 197, 146, 0.7);
$header-height: 79px;
$border-radius: 50px * $scale;
$white: #FFFAFE;

.inventory-modal {
  .modal-body {
    background: $bg-color;
    -webkit-box-shadow: $sh-h $sh-v $sh-bl $sh-spr $sh-col;
    -moz-box-shadow: $sh-h $sh-v $sh-bl $sh-spr $sh-col;
    box-shadow: $sh-h $sh-v $sh-bl $sh-spr $sh-col;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal-header {
    background: $bg-color;
  }

  color: white;

  h1 {
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
  }

  h3 {
    font-family: input-sans-compressed, sans-serif;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 39px;
  }

  .firebase-image, .firebase-image-error, .firebase-image-loading, canvas {
    width: 100%;
    height: auto;
    min-height: 200px;
    -webkit-border-top-left-radius: $border-radius;
    -moz-border-radius-topleft: $border-radius;
    -webkit-border-bottom-right-radius: $border-radius;
    -moz-border-radius-bottomright: $border-radius;
    border-radius: $border-radius 0 $border-radius 0;
    margin-bottom: 21px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    color: white;
    right: 10px;
  }
}
