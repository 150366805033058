$h1-color: #ffffff;
$h1-size: 21px;

$h2-color: #ffffff;
$h2-size: 12px;

$link-color: #ffffff;
$link-size: 12px;

$text-color: #ffffff;
$text-size: 1.5rem;

.navigation {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  padding: 30px 30px 25px;
  min-width: 250px;

  .h1-container {
    display: flex;
    align-items: center;
    margin-bottom: 31.5px;

    h1 {
      position: relative;
      top: 2px;
      flex: 1;
      margin: 0;
      padding-bottom: 7.5px;
      font-size: $h1-size;
      color: $h1-color;
    }

    // Underline
    h1:after {
      content: '';
      width: 23px;
      height: 3px;
      background: rgba(242, 163, 161, 1);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  h2 {
    font-size: $h2-size;
    color: $h2-color;
    margin: 0;
    margin-bottom: 3px;
  }

  a, p {
    font-size: $link-size;
    color: $link-color;
    text-transform: uppercase;
    margin: 0;
  }

  .bucket {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    margin-bottom: 40px;
  }

  .stretch-bucket {
    flex: 1;
  }

  .bottom-bucket {
    margin-bottom: 0;
  }

  .section-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .book-image {
    width: 70px;
    height: auto;
    margin-right: 10px;
  }

  .book-container {
    display: flex;
  }
}
