$bg-color: rgb(137, 201, 188);
$border-radius: 20px;

.claim {
  background: $bg-color;
  color: white;
  padding-top: 100px !important;

  h1 {
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
  }

  h3 {
    font-family: input-sans-compressed, sans-serif;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 39px;
  }

  .claim-description {
    flex: 1
  }

  .claim-image {
    width: 100%;
    -webkit-border-top-left-radius: $border-radius;
    -moz-border-radius-topleft: $border-radius;
    -webkit-border-bottom-right-radius: $border-radius;
    -moz-border-radius-bottomright: $border-radius;
    border-radius: $border-radius 0 $border-radius 0;
    margin-bottom: 21px;
  }

  .claim-button-container {

  }
}
