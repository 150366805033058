.scan-button {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .scan-button-image {
    width: 92px;
    height: 92px;
  }

  .scan-button-label {
    font-family: rhode, sans-serif;
    text-transform: uppercase;
    position: absolute;
    color: #ffffff;
  }
}
