.registry-logo {
  display: flex;
  flex: 0;
}

$foreground-color: rgba(223, 110, 191, 1);
$foreground-color-mono: rgba(255, 255, 255, 1);
$foreground-color-dark: rgba(50, 50, 50, 1);
$background-color: rgba(223, 110, 191, 0.5);
$background-color-mono: rgba(255, 255, 255, 0.5);
$background-color-dark: rgba(50, 50, 50, 0.5);

$square-side: 25px;
$top-offset: 6px;
$left-offset: 8px;
$border-radius: 2px;
.squares {
  margin: 1px 17px 0 0;

  .square {
    width: $square-side;
    height: $square-side;

    -webkit-border-top-left-radius: $border-radius;
    -moz-border-radius-topleft: $border-radius;
    border-top-left-radius: $border-radius;

    -webkit-border-bottom-right-radius: $border-radius;
    -moz-border-radius-bottomright: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .background {
    background: $background-color;
  }

  .foreground {
    position: relative;
    top: -($square-side - $top-offset);
    left: $left-offset;
    background: $foreground-color;
  }
}

.registry-logo.logo-mono {
  .background {
    background: $background-color-mono;
  }

  .foreground {
    background: $foreground-color-mono;
  }
}

.registry-logo.logo-dark {
  .logo-text {
    color: #666666 !important;
  }
}

.logo-text {
  display: flex;
  flex-flow: column nowrap;
  color: #ffffff !important;
  font-family: rhode, sans-serif;
  text-transform: uppercase;

  span {
    padding: 0;
    margin: -2px;
    font-size: 13px;
  }

  span.the {
    font-size: 10px;
  }
}
