@import '../../../css/z-indices';

.scanner {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  padding: 150px 10vw 100px;
  z-index: $z-scanner;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .scanner-instructions {
    font-family: rhode, sans-serif;
    color: rgba(227, 215, 190, 0.5);
    font-size: 21px;
    text-align: center;
    flex: 1;
  }

  .scanner-pad {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fingerprint {
    width: 60vw;
    max-width: 172px;
    height: auto;
  }

  .human-warning {
    width: 150px;
    height: 150px;
    align-self: center;
    margin-bottom: 30px;
  }
}

.scanner.human-detected {
  .scanner-instructions {
    color: rgb(255, 255, 255);
  }
}
