$radius: 12px;

.dragger {
  .dragger-track {
    height: 3px;
    margin-bottom: 16px;
  }

  .dragger-handle {
    //-webkit-box-shadow: 0 1px 3px rgba(0,0,0,.4), 0 -1px 3px rgba(0,0,0,0.4);
    //-moz-box-shadow: 0 1px 3px rgba(0,0,0,.4), 0 -1px 3px rgba(0,0,0,0.4);
    box-shadow: 0 2px 2px rgba(0,0,0,.2);//, 0 -1px 3px rgba(0,0,0,0.4);
    width: $radius * 2;
    height: $radius * 2;
    border-radius: $radius * 2;
    position: relative;
    top: -$radius;
    cursor: pointer;
    border: none;
    background: rgba(253, 173, 206, 1); // Sampled
  }

  .dragger-handle:after {
    content: ' ';
    position: absolute;
    width: $radius;
    height: $radius;
    top: $radius * 0.5;
    left: $radius * 0.5;
    border-radius: 50%;
    background-color: #ffffff;
    //box-shadow: 0 1px 3px rgba(0,0,0,0.4) inset, 0 -1px 3px rgba(0,0,0,.4) inset;
  }
}

.dragger.non-interactive {
  .dragger-track {
    margin-bottom: 5px;
  }

  .dragger-handle {
    display: none;
  }
}

