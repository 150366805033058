.stats-display {
  display: flex;
  margin-bottom: 30px;
}

.flex-spacer {
  flex: 1;
}

.currency-display {
  color: white;

  .currency-label {
    text-transform: uppercase;

    display: block;
    height: 31px;
    padding: 8px 27px;
    background: #481D64;
    -moz-box-shadow:    inset 0 -2px 6px #2B1939;
    -webkit-box-shadow: inset 0 -2px 6px #2B1939;
    box-shadow:         inset 0 -2px 6px #2B1939;
  }

  .currency-container {
    position: relative;
    top: 6px;
    display: flex;
    align-items: flex-end;
  }

  .currency-image {
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    border: none;
  }
}

.currency-value {
  font-family: rhode, sans-serif;
  font-size: 14px;
}

.currency-rate {
  margin-left: 10px;
}

.primary-currency {
  .currency-label {
    border-radius: 0 0 20px 0;
    width: 150px;
    padding-left: 58px;
  }

  .currency-container {
    left: 58px;
  }

  .currency-image {
    left: 20px;
    top: 88px;
  }
}

.secondary-currency {
  .currency-label {
    border-radius: 0 0 0 20px;
    width: 114px;
  }

  .currency-container {
    left: 27px;
  }

  .currency-image {
    top: 88px;
    right: 20px;
  }
}

