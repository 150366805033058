.item-display {
  display: flex;
  flex-direction: row;
  padding: 0;
  min-height: 140px;
}

.locked-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
}

.item-image {
  width: 100px;
  background-size: cover;
  color: white;
  margin: 0 15px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
}

.item-count {
  padding-right: 5px;
}

.item-count-x, .item-count {
  font-family: rhode, sans-serif;
  color: rgba(255, 255, 255, 0.7);
}

.item-count-x {
  font-size: 25px;
}

.item-count-num {
  font-size: 50px;
  line-height: 18px;
}

.item-info {
  flex: 1;
  color: white;

  display: flex;
  flex-direction: column;
  padding: 13px 20px 13px 0;
}

.item-title {
  display: flex;
  margin: 0;
  line-height: 14px;
  margin-bottom: 10px;
}

.item-title-name {
  flex: 1;
  font-size: 12px;
  font-family: rhode, sans-serif;
  text-transform: uppercase;
}

.item-rate, .item-flavor {
  margin: 0;
  line-height: 12px;
}

.item-rate {
  font-size: 12px;
  margin-bottom: 10px;
}

.item-flavor {
  margin-bottom: 15px;
}

.price-display {
  display: flex;
  align-items: center;
  font-size: 12px;

  svg {
    margin-right: 5px;
  }
}

.item-actions {
  display: flex;
  flex-flow: row wrap;

  .action-button {
    margin-right: 10px;

    * {
      pointer-events: none;
    }

    //&:not(:last-child) {
      margin-bottom: 10px;
    //}
  }
}

.unlock-button {
  z-index: 1;
}
