.float-dialog {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 20px 0 20px 0;
  max-width: 300px;
  text-align: center;
  position: absolute;
  top: 75%;
}

#instructions {
  pointer-events: none;
}

#final-dialog {

}
