$color-primary: rgba(242, 163, 161, 1);
$color-secondary: rgba(246, 245, 240, 1);
$border-radius: 20px;

.double-button {
  display: flex;

  button {
    font-family: rhode, sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    height: 50px;

    -webkit-border-top-left-radius: $border-radius;
    -moz-border-radius-topleft: $border-radius;
    -webkit-border-bottom-right-radius: $border-radius;
    -moz-border-radius-bottomright: $border-radius;
    border-radius: $border-radius 0 $border-radius 0;
  }

  .double-primary {
    color: $color-primary;
    background: $color-secondary !important;
  }

  .double-secondary {
    color: $color-secondary;
    background: $color-primary !important;
    margin-right: 14px;
  }
}

.double-button button {
  flex: 1;
}
