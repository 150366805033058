.action-header {
  display: flex;
  color: white;
  align-items: center;
  margin-bottom: 5px;

  h2 {
    margin-bottom: 0 !important;
    margin-right: 5px;
  }

  .refresh-button {
    background: #ffffff;
    border-radius: 5px;
  }
}
