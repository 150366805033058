.welcome-modal {
  #welcome-name {
    color: black !important;
    border: 1px solid gray;
    margin-right: 10px;
  }

  .welcome-form {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .dialog-error {
    color: red;
  }
}
