@import '../../../css/z-indices';

/* based on .v-padded-age */
.space-race {
  background-image: url('/images/nebula.jpg');
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;
  flex: 1;
  overflow: hidden;
  padding-top: 80px;

  * {
    //scrollbar-color: white black;
  }
}

.action-button {
  background: rgba(72, 29, 100, 1);
  padding: 2px 15px;
  border-radius: 10px 0 10px 0;
  //text-transform: uppercase;
  font-size: 12px;
  color: white;

  &.selected {
    background: #2B1939;
    color: white;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.sub-button {
    font-size: 10px;
  }
}

.muted {
  font-style: italic;
  opacity: 0.5;
  font-size: 10px;
}
