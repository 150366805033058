.id-card {
  .close-btn {
    margin-top: 3px;
  }

  .modal-body {
    display: flex;

    #react-qrcode-logo {
      box-shadow: 0 1px 2px 2px rgba(0,0,0,.4);
      margin-right: 20px;
      //border: 1px solid gray;
    }

    p {
      font-size: 15px;
    }
  }
}
