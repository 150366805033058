@import '../../../css/z-indices';

.header-navbar {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: $z-header;
  display: flex;
  padding: 20px 0 0 20px;
  left: 0;
  right: 0;

  .spacer, svg {
    flex: 1
  }

  &.space-race-page {
    .registry-logo {
      display: none;
    }
  }
}

.header-navbar.logo-mono {
  background: unset;
}
