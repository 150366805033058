.upgrade-display {
  flex: 1 50%;
  padding: 10px 10px 20px;
  //background: rgba(93, 26, 137, 0.1)
  //background: rgba(0, 0, 0, 0.1)
}

.upgrade-item-info {
  display: flex;
  flex-direction: column;
}

.upgrade-item-title {
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 5px;
}

.upgrade-item-description {
  margin-bottom: 10px;
}
