.species-display {
  margin-bottom: 16px;

  p {
    margin: 0;
  }

  .edit-species {
    display: flex;
  }

  input {
    width: 80vw;
  }

  .form-text {
    color: red;
  }

  .species-edit {
    margin-top: -5px;
  }

  .form-group {
    margin-bottom: 5px;
  }
}

