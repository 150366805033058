.traits-display {
  margin-bottom: 33px;

  .dragger-label {
    color: white;
    font-size: 12px;
  }
}

.traits-display.td-non-interactive {
  .dragger-label {
    margin-bottom: 0;
  }
}

.trait-name-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.td-non-interactive .trait-name-container {
  margin-bottom: 3px;
}

.td-non-interactive .rangeslider__handle {
  display: none;
}

.traits-display .rangeslider__fill {
  background-color: #F3A2A3;
}
