$white: #FFFAFE;
$foreground-color: rgb(223, 110, 191);

.full-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  //position: fixed;
  //overflow-x: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently supported by Chrome and Opera */
  margin: 0;
  padding: 0;

  font-family: input-sans-compressed, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: fixed;
  //overflow: auto;
}

a {
  color: $white;
}

.full-page, .padded-page {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.padded-page {
  padding: 60px 10vw 10px;
}

.v-padded-page {
  padding: 40px 0 10px;
}

.flex-spacer {
  flex: 1;
}

h1, h2, h3, h4, h5, h6 {
  font-family: rhode, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

h1 {
  font-size: 36px;
  hyphens: auto;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 21px;
}

$scale: 0.4;
$border-radius: 20px * $scale;
.button-small {
  font-family: rhode, sans-serif;
  text-transform: uppercase;
  background: white;
  border: none;
  padding: 5px 10px;

  -webkit-border-top-left-radius: $border-radius;
  -moz-border-radius-topleft: $border-radius;
  -webkit-border-bottom-right-radius: $border-radius;
  -moz-border-radius-bottomright: $border-radius;
  border-radius: $border-radius 0 $border-radius 0;

  //position: relative;
  //background: none;
  //color: black;
  //mix-blend-mode: screen;
}

.button-small.light {
  color: rgba(223, 110, 191, 1); // Fake knockout
  background: white !important;
}

.float-button {
  position: fixed;
  right: 40px;
  top: 56px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 15px;
  color: $foreground-color;
  background: white !important;
  border: none;
}

$side-padding: 10vw;
.button-container {
  padding: 0 $side-padding;
}

//.button-small::before {
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  background: white;
//  border-radius: 0.3em;
//  content: '';
//  mix-blend-mode: color-burn;
//}

.error-message {
  color: red;
  margin-top: 10px;
}

// TEMP
.btn-primary {
  background: #333333 !important;
  border: none !important;
}

.icon-small {
  max-width: 15px;
  max-height: 15px;
}

.text-field {
  height: 40px;
  line-height: 40px;
  max-width: 80vw;
  //background: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-family: input-sans-compressed, sans-serif;
  color: #ffffff;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input, select {
  font-size: 14px;
  font-family: input-sans-compressed, sans-serif;
  color: #ffffff;
  line-height: 15px;
  padding: 13px 10px;
  margin: 0;
}

select {
  display: block;

  //width: 100%;
  //max-width: 100%;
  //box-sizing: border-box;

  //border: 1px solid #aaa;
  //box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  //border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  //appearance: none;
  background-color: rgba(255, 255, 255, 0);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;

  border: none;
  border-radius: 0;
  text-overflow: ellipsis;

  option {
    font-weight:normal;
  }
}
select::-ms-expand {
  display: none;
}
select:hover {
  border-color: #ffffff;
}
select:focus {
  border-color: #ffffff;
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 1);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  outline: none;
}

input:not([type='checkbox']) {
  background: rgba(255, 255, 255, 0.2);
  border: none;
}

input:focus {
  border-color: #ffffff;
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 1);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  outline: none;
}

input.error {
  color: rgb(255, 53, 53);
  border-color: rgb(255, 53, 53);
  box-shadow: 0 0 1px 3px rgba(255, 53, 53, 1);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  outline: none;
}

input[type='checkbox'] {
  margin: 0;
}

.checkbox-group {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  input[type='checkbox'] {
    margin-right: 10px;
  }
}

input:not([type='checkbox']) {
  width: 80vw;
  color: white !important;
}

.text-muted {
  color: white !important;
  a {
    text-decoration: underline;
  }
}

.form-label {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-family: rhode, sans-serif;
  font-weight: normal;
}

.checkbox-group .form-label {
  text-transform: none;
  font-size: 10px;
  font-family: input-sans-compressed, sans-serif;
}

.error {
  //color: rgb(255, 53, 53) !important;

  a {
    //color: rgb(255, 53, 53) !important;
    text-decoration: underline !important;
    font-weight: 700;
  }
}

::-webkit-input-placeholder { /* Edge */
  color: rgba(255, 255, 255, 0.5);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.5);
}

::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.emphasis {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.modal-dialog a {
  color: #df6ebf;
  font-weight: 700;
}

.img-icon {
  margin-right: 10px;
}

.img-icon-inline {
  margin-right: 3px;
}

button {
  -webkit-appearance: none;
}

button:focus {
  outline: 0;
}

.nodrag {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}
