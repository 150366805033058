.sign-up {
  .species-checkbox {
    font-size: 1.5rem;
    margin-top: 2rem;
  }

  .submitting {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h2.welcome {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 17px;
  }

  .book-container {
    display: flex;
    margin-bottom: 33px;

    img {
      margin-right: 10px;
      margin-top: 3px;
      width: 75px;
      min-width: 75px;
      height: 115px;
      min-height: 115px;
    }

    p {
      font-size: 12px;
    }
  }

  .icon-small {
    color: white;
  }

  .adding-secondary-species {
    background: #f2a3a1;
  }
}
