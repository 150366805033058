@import '../../../css/z-indices';

.app {
  flex: 1;
  display: flex;
  //z-index: -1;
  //padding: 0 2rem 2rem;
  //overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;;
  background: #000000;
}

.registry-sidebar {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#df6ebf+0,d6a683+100 */
  background: #df6ebf; /* Old browsers */
  background: -moz-linear-gradient(top,  #df6ebf 0%, #d6a683 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #df6ebf 0%,#d6a683 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #df6ebf 0%,#d6a683 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#df6ebf', endColorstr='#d6a683',GradientType=0 ); /* IE6-9 */

  display: flex;
  z-index: $z-sidebar !important;
}

.registry-sidebar-content {
  display: flex;
  flex-flow: column nowrap;
}

.registry-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
  z-index: $z-sidebar-overlay !important;
}

.form-check-label {
  margin-left: 20px;
}
