.intro-page {
  flex: 1;

  background: black;
  overflow: hidden;

  // TODO
  z-index: 5;

  .background-image {
    opacity: 0.8;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .intro-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro-content {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50px 0 50px 0;
    width: 90vw;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
  }

  .gr-logo {
    width: 200px;
    height: auto;
    padding-bottom: 50px;
  }

  .intro-subtitle {
    color: white;
    text-align: center;
    font-size: 14px;
    margin-bottom: 50px;
  }

  .button {
    //background: rgb(223, 110, 191);
    background: rgba(255, 255, 255, 0.8);
    color: black;
    text-transform: uppercase;
    padding: 5px 20px;
    border-radius: 10px 0 10px 0;
    margin-bottom: 10px;
    width: 200px;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }

  .sign-in-link {
    margin-top: 20px;
    text-decoration: underline;
  }
}
