.home-page-main {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 100px !important;
  padding-bottom: 40px !important;

  h1 {
    color: white;
  }

  .species-list {
    background: rgba(255, 255, 255, 0.1);
    list-style: none;
    margin: 20px 0;
    padding: 10px;
    align-self: stretch;
    min-height: 100px;
    flex: 1;
    border-radius: 5px;
  }

  .list-item {
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    border-radius: 10px 0 10px 0;
    margin-bottom: 10px;

    a {
      font-family: rhode, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      color: #df6ebf;
    }
  }

  #add-species {
    align-self: flex-end;
  }
}

