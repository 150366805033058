$background: #000000; //#F6F5F0;
$header-color: rgba(253, 197, 146, 0.7);
$white: #FFFAFE;

.species-list-3d {
  background: $background;

  #search-message {
    position: absolute;
    top: 300px;
    text-align: center;
    width: 100vw;

    p {
      color: #666666;
      text-transform: uppercase;
    }
  }

  #menu {
    position: absolute;
    top: 80px;
    width: 100%;
    text-align: center;
  }

  #controls {
    position: absolute;
    top: 80px;
    text-align: center;

    display: flex;
    flex-direction: column;
    width: 100vw;

    #ctrl-shift-up, #ctrl-shift-down {
      background: none;
      border: none;
    }

    form {
      flex: 1;
      display: flex;
      padding: 0 5vw;
      align-items: center;
      justify-content: center;
    }

    //$inputBackground: rgba(100, 100, 100, 0.7);
    $inputBackground: rgba(58, 58, 58, 1);
    input {
      border-radius: 5px 0 0 5px;
      width: 60vw;
      border: none !important;
      background: $inputBackground;
      text-transform: uppercase;
    }

    input:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }

    .search-btn, .clear-btn {
      border: none;
      align-self: stretch;
      flex: 0;
    }

    .clear-btn {
      border-radius: 0;
      background: $inputBackground;
      min-width: 36px;
    }

    .search-btn {
      background: #793867;
      border-radius: 0 5px 5px 0;
    }
  }

  //#ctrl-shift-up {
  //  position: absolute;
  //  display: block;
  //  margin: 0 auto;
  //  bottom: 100px;
  //}
  //
  //#ctrl-shift-down {
  //  position: absolute;
  //  top: 100px;
  //}

  // Scaled at 0.5 original of 252x252
  $scale: 0.5;
  $sh-h: 0 * $scale;
  $sh-v: 0 * $scale;
  $sh-bl: 40px * $scale;
  $sh-spr: 0 * $scale;
  $sh-col: rgba(0, 0, 0, 1);

  $s-scale: 1;
  $s-sh-h: 0 * $s-scale;
  $s-sh-v: 0 * $s-scale;
  $s-sh-bl: 20px * $s-scale;
  $s-sh-spr: 10px * $s-scale;
  $s-sh-col: rgba(213, 152, 213, 1);
  //$sh-col: rgba(255, 143, 255, 1);

  .element {
    width: 252px * $scale;
    height: 252px * $scale;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //background-color: rgba(0, 127, 127, 0.95);

    cursor: default;

    -webkit-border-top-left-radius: 20px * $scale;
    -webkit-border-bottom-right-radius: 20px * $scale;
    -moz-border-radius-topleft: 20px * $scale;
    -moz-border-radius-bottomright: 20px * $scale;
    border-top-left-radius: 20px * $scale;
    border-bottom-right-radius: 20px * $scale;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d6a583+1,ea6ede+100 */
    //background: #d6a583; /* Old browsers */
    background-color: #F46BD7;
    background-image: url("/images/registry-card.png");
    background-size: cover;
    //background: -moz-linear-gradient(top,  #d6a583 1%, #ea6ede 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(top,  #d6a583 1%,#ea6ede 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to bottom,  #d6a583 1%,#ea6ede 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6a583', endColorstr='#ea6ede',GradientType=0 ); /* IE6-9 */

    //box-shadow: 0 0 40px * $scale rgba(255, 143, 255, 1);

    -webkit-box-shadow: $sh-h $sh-v $sh-bl $sh-spr $sh-col;
    -moz-box-shadow: $sh-h $sh-v $sh-bl $sh-spr $sh-col;
    box-shadow: $sh-h $sh-v $sh-bl $sh-spr $sh-col;

    &.selected {
      //border: 3px solid rgba(255, 255, 255, 1);
      -webkit-box-shadow: $s-sh-h $s-sh-v $s-sh-bl $s-sh-spr $s-sh-col;
      -moz-box-shadow: $s-sh-h $s-sh-v $s-sh-bl $s-sh-spr $s-sh-col;
      box-shadow: $s-sh-h $s-sh-v $s-sh-bl $s-sh-spr $s-sh-col;
      background-image: url("/images/registry-card-selected.png");
    }

    &.extinct {
      filter: brightness(30%);
    }

    .element-header {
      //-webkit-border-top-left-radius: 20px;
      //-webkit-border-bottom-right-radius: 0;
      //-moz-border-radius-topleft: 20px;
      //-moz-border-radius-bottomright: 0;
      //border-top-left-radius: 20px;
      //border-bottom-right-radius: 0;

      padding: 0 10px;
      height: 47px * $scale;
      display: flex;
      align-items: center;
      //background: rgba(253, 197, 146, 0.7);
    }

    .element-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 0;
    }

    $line-height: 1.7rem;
    $lines: 4;
    .species-name {
      flex: 1;
      font-size: 30px * $scale;
      color: rgba(255, 255, 255, 0.75);
      //margin-bottom: 10px;
      //word-break: break-word;
      //white-space: normal;
      //text-align: left;
      //hyphens: auto;

      color: #FFFAFE;
      font-family: rhode, sans-serif;
      text-transform: uppercase;

      margin: 0;
      padding: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: $lines; /* number of lines to show */
      //line-height: $line-height;        /* fallback */
      //max-height: $line-height * 3;       /* fallback */
    }

    .tier-display {
      font-size: 15px;
      font-weight: bold;
      color: #FFFAFE;
      text-align: left;
      flex: 1;
    }

    .inventory-display {
      background: $white;
      border-radius: 10px;
      padding: 0 5px;
    }
  }

  .element:hover {
    //box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    //border: 1px solid rgba(127, 255, 255, 0.75);
  }

  #menu {
    button {
      //color: rgba(127, 255, 255, 0.75);
      color: $white;
      background: transparent;
      outline: 1px solid $white;
      border: 0;
      padding: 5px 10px;
      cursor: pointer;
    }

    button:hover {
      background-color: rgba(255, 143, 255, 0.5)
    }

    button:active {
      color: #000000;
      background-color: rgba(255, 143, 255, 1)
    }
  }
}
