$scale: 1;
$sh-h: 0 * $scale;
$sh-v: 0 * $scale;
$sh-bl: 40px * $scale;
$sh-spr: 0 * $scale;
$sh-col: rgba(255, 143, 255, 0.7);
$header-color: rgba(253, 197, 146, 0.7);
$header-height: 79px;
$border-radius: 50px * $scale;
$white: #FFFAFE;

.species-page-shell {
  // temp
  background: #ff0000;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;

  h1 {
    margin-bottom: 0;
    margin-top: 10px;
  }

  .species-display {
    margin-top: 10px;
  }

  .species-description {
    p {
      margin: 0;
      font-style: italic;
    }
    margin-bottom: 25px;
  }

  .leader-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;

    .leader-container-info {
      flex: 1;

      p {
        margin: 0;
      }
    }

    .card-icon {
      margin-top: -5px;
    }
  }

  .species-page-header {
    display: flex;
    align-items: center;

    h1 {
      margin: 0;
      max-width: 80vw;
      overflow: hidden;
    }

    a {
      margin-right: 10px;
    }
  }
}
