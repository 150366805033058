.account {
  form {
    margin-bottom: 50px;
  }

  .password-change-form {
    //margin-bottom: 50px;
  }

  button {
    margin-bottom: 10px;
  }
}
