.inventory-display {
  h2 {
    //margin-bottom: 17px !important;
  }

  .inventory-list {
    margin-top: 12px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 15px;
  }

  $inventory-scale: 1;
  $border-radius: 10px;
  .inventory-item {
    background: rgba(255, 255, 255, 0.1) center center no-repeat;
    background-size: cover;
    border-radius: $border-radius * $inventory-scale 0 $border-radius * $inventory-scale 0;
    //padding: 5px * $inventory-scale 5px * $inventory-scale 0;
    width: 56px * $inventory-scale;
    height: 54px * $inventory-scale;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    margin-right: 5px;
    margin-bottom: 5px;
    color: white;
  }

  .inventory-title {
    color: rgb(51, 51, 51);
    font-size: 8px;
    text-align: center;
    background: rgb(108, 235, 209);
    border-radius: 0 0 $border-radius * $inventory-scale 0;
  }
}
