$side-padding: 10vw;

// TODO Move this up. It's now used in SignIn and PasswordForget.
.common-page {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  p {
    color: white;
  }

  h1 {
    color: white;
    font-size: 24px;
    margin-bottom: 25px;
  }

  h2 {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 5px;
    color: white;
  }

  .species-form {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
  }

  .intro {
    margin-bottom: 20px;
  }

  .scroller {
    flex: 1;
    overflow: auto;
    margin-top: 35px;
    margin-bottom: 10px;
    padding: 20px $side-padding 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}
