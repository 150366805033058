.upgrade-container {
  display: flex;
  flex-flow: row wrap;
}

.structures-intro {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.2);
  margin: 0;
  color: white;
  text-align: center;
  border-bottom: 1px solid rgba(72, 29, 100, 1);
}

.building-panel {
  overflow: hidden;
}

.building-panel-small {
  height: 40px;
}
