.leader-display {
  margin-bottom: 20px;

  p {
    margin: 0;
  }

  .edit-leader {
    display: flex;
  }

  .leader-title {
    width: 38vw;
    margin-right: 2vw;
  }

  .leader-name {
    max-width: 40vw;
    text-overflow: ellipsis;
  }

  .leader-edit {
    margin-top: -5px;
  }
}
