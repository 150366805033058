.email-form {
  margin-bottom: 2rem;

  .form-group {
    margin-bottom: 31px;
  }

  small {
    font-size: 10px;
    margin-top: 5px;
  }

  color: white;

  #email {
    margin-bottom: 5px;
  }

  //.form-element {
  //  display: flex;
  //
  //  input {
  //    flex: 0 50%
  //  }
  //}
}
